
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.esmp-textarea {
  &__input {
    min-height: 100px;
    background: #f3f3f4;
    padding: 3px 12px;
    border-radius: 8px;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
    color: #101828;
    font-size: 16px;
    line-height: 24px;
    resize: vertical;
    border: none;
  }
}
