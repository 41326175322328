
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.esmp-modal-body {
  font-size: 16px;
}
.escalation-form-element {
  margin: 20px 0 0 0;
  &:first-child {
    margin: 0;
  }
}
